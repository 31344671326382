<template>
  <div class="personalbox">
    <div class="centerTop">
      <img
        class="opcityBg"
        v-if="userInfo.iconUrlSmall"
        :src="userInfo.iconUrlSmall"
        alt=""
      />
      <img v-if="!userInfo.iconUrlSmall" src="~img/default_head@2x.png" alt="" />
      <div class="content">
        <a href="javaScript:" class="back" @click="seeBack">
          <img src="~img/back@2x.png" alt="" />
        </a>
        <div class="infobox clearfix" @click="goMyAccount">
          <img
            class="fl"
            v-if="userInfo.iconUrlSmall"
            :src="userInfo.iconUrlSmall"
            alt=""
          />
          <div class="infoxx fl">
            <p class="user">{{ userInfo.name }}</p>
            <p class="userId">ID: {{ userInfo.id }}</p>
          </div>
        </div>
        <div class="historybox" v-if="userInfo.lastReadRecord">
          <img src="~img/account/huwen.png" class="bg" alt="" />
          <div class="clearfix historyCon">
            <p class="fl ellipsis">
              上次閱讀：{{ userInfo.lastReadRecord.bookName }}
              {{ userInfo.lastReadRecord.chapterName }}
            </p>
            <router-link
              class="fr"
              :to="
                '/book/' +
                  userInfo.lastReadRecord.bookId +
                  '/' +
                  userInfo.lastReadRecord.chapterId
              "
              >繼續閱讀 ></router-link
            >
          </div>
        </div>
      </div>
    </div>
    <ul class="centenList">
      <li class="clearfix">
        <router-link class="clearfix" to="/wallet">
          <img class="fl item" src="~img/account/icon_wallet@2x.png" alt="" />
          <p class="fl">
            錢包<span>餘額：{{ userInfo.yuanBi }}書幣</span>
          </p>
          <button class="fr">充值</button>
        </router-link>
      </li>
      <li class="clearfix">
        <router-link class="clearfix" to="/history">
          <img class="fl item" src="~img/account/icon_consume@2x.png" alt="" />
          <p class="fl">瀏覽記錄</p>

          <img class="fr" src="~img/home/Path7@2x.png" alt="" />
        </router-link>
      </li>
      <li class="clearfix">
        <router-link class="clearfix" to="/collect">
          <img
            class="fl item"
            src="~img/account/icon_collection@2x.png"
            alt=""
          />
          <p class="fl">我的收藏</p>
          <img class="fr" src="~img/home/Path7@2x.png" alt="" />
        </router-link>
      </li>
      <li class="clearfix">
        <router-link class="clearfix" to="/message">
          <img class="fl item" src="~img/account/icon_new@2x.png" alt="" />
          <p class="fl">消息通知</p>
          <img class="fr" src="~img/home/Path7@2x.png" alt="" />
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { getuserInfo } from "apiurl/user";
import { mapState } from "vuex";
export default {
  data() {
    return {
      userInfo: {}
    };
  },
  created() {
    this.$nextTick(() => {
      // 獲取用戶信息
      this.getUserInfo();
    });
  },
  computed: mapState({
    info: state => state.user.img
  }),
  methods: {
    async getUserInfo() {
      let res = await getuserInfo();
      if (res.success) {
        console.log(this.userInfo)
        this.userInfo = res.data;
      }
    },
    seeBack() {
      if (this.$route.query.back) {
        this.$router.push("/");
      } else {
        this.$router.go(-1);
      }
    },
    goMyAccount() {
      this.$router.push("/myAccount");
    }
  }
};
</script>

<style lang="stylus" scoped>
.centerTop
    position relative
    height 251px
    overflow hidden
    padding 27px 24px 0
    box-shadow 0px -1px 13px 0px rgba(0, 0, 0, 0.12)
    .opcityBg
        width 100%
        position absolute
        -webkit-filter blur(40px)
        opacity 0.4
        z-index -1
    .content
        height 250px
        background #fff
        position relative
        border-radius 36px 36px 0 0
        .back
            width 18px
            display block
            padding 29px 24px 0
            img
                width 18px
        .infobox
            padding 0 24px
            position absolute
            width 100%
            box-sizing border-box
            top 60px
            left 0
            z-index 10
            img
                width 120px
                height 120px
                border-radius 50%
                margin-right 23px
            .infoxx
                margin 19px 0 16px
                color #333333
                .userId
                    color rgba(153, 153, 153, 1)
                    font-size 24px
                    line-height 33px
                    margin-top 7px
        .historybox
            position absolute
            height 88px
            width 100%
            bottom 0
            z-index 0
            .bg
                position absolute
                height 88px
                width 100%
            .historyCon
                position absolute
                top 32px
                padding 0 24px 0 35px
                width 100%
                box-sizing border-box
                font-weight 500
                p
                    max-width 70%
                a
                    font-size 22px
.centenList
    margin 10px 46px 0 52px
    li
        padding 27px 0
        border-bottom 1px solid #eee
        &:first-child
            padding 27px 0 20px
        .item
            width 36px
            height 36px
        a
            img
                width 14px
                &.fr
                    margin-top 7px
        button
            padding 3px 16px
            background rgba(92, 162, 245, 1)
            border-radius 23px
            color #fff
        p
            color #323232
            line-height 36px
            font-size 30px
            margin-left 21px
            span
                color #999999
                display inline-block
                margin-left 5px
                font-size 24px
</style>
